import React from 'react';
import './ConLeft.css';
import logo from '../../../images/logo_sante.png';

function ConLeft() {
  return (
    <div className="ConLeft">

      <div className='main_div_left'>
        <img className='img_logo_left' src={logo} alt="Logo" />
      </div>
      
    </div>
  );
}

export default ConLeft;