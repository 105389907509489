import React, { useRef } from 'react';
import './ConRight.css';

function ConRight({ handleSubmit }) {
    const cpfRef = useRef(null);
    const senhaRef = useRef(null);

    const onSubmit = (e) => {
        e.preventDefault();
        const cpf = cpfRef.current.value;
        const senha = senhaRef.current.value;
        handleSubmit(cpf, senha);
    };

    return (
        <div className="ConRight">
            <div className='main_div_right'>

                <div className='row inner_div_right'>
                    <h5 className='col s12 login_title'>Login</h5>
                    <form className="col s12 form_login" onSubmit={onSubmit}>
                        <div className="row">
                        <div className="col s12">
                            <label htmlFor="cpf">Telefone</label>
                            <input id="cpf" type="text" className="validate" required ref={cpfRef} autoComplete="off"/>
                        </div>
                        <div className="col s12">
                            <label htmlFor="senha">Senha</label>
                            <input id="senha" type="password" className="validate" required ref={senhaRef} autoComplete="off"/>
                        </div>
                        <div className="col s12 div_btn_form_right">
                            <button className="waves-effect waves-light waves-white btn right btn_form_right" type="submit">
                                Confirmar
                            </button>
                        </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    );
}

export default ConRight;
