const SERVER_URL = 'https://santeskills.com/Controller/';
const LOCAL_URL = 'http://localhost/sante/Controller';

const BASE_URL = SERVER_URL;

export const URLS = {
    LOGIN_ADMIN: `${BASE_URL}/usuario/loginAdmin.php`,

    CREATE_PRODUTO: `${BASE_URL}/produto/produtoCreate.php`,
    UPDATE_PRODUTO: `${BASE_URL}/produto/produtoUpdate.php`,
    DELETE_PRODUTO: `${BASE_URL}/produto/produtoDelete.php`,
    READ_PRODUTO: `${BASE_URL}/produto/produtoRead.php`,
    READ_ALL_PRODUTO: `${BASE_URL}/produto/produtoReadAll.php`,

    CREATE_TESTE: `${BASE_URL}/teste/testeCreate.php`,
    CREATE_PRODUTO_TESTE: `${BASE_URL}/teste/produtoTesteCreate.php`,
    UPDATE_TESTE: `${BASE_URL}/teste/testeUpdate.php`,
    DELETE_PRODUTO_TESTE: `${BASE_URL}/teste/produtoTesteDelete.php`,
    DELETE_TESTE: `${BASE_URL}/teste/testeDelete.php`,
    READ_ALL_TESTE: `${BASE_URL}/teste/testeReadAll.php`,
    READ_PRODUTO_TESTE_BY_TEST_ID: `${BASE_URL}/teste/produtoTesteReadByTestId.php`,

    CREATE_VIDEO: `${BASE_URL}/video/videoCreate.php`,
    CREATE_PRODUTO_VIDEO: `${BASE_URL}/video/produtoVideoCreate.php`,
    READ_ALL_VIDEO: `${BASE_URL}/video/videoReadAll.php`,
    UPDATE_VIDEO: `${BASE_URL}/video/videoUpdate.php`,
    DELETE_VIDEO: `${BASE_URL}/video/videoDelete.php`,
    DELETE_PRODUTO_VIDEO: `${BASE_URL}/video/produtoVideoDelete.php`,
    READ_PRODUTO_VIDEO_BY_VIDEO_ID: `${BASE_URL}/video/produtoVideoReadByVideoId.php`,

    ASSINA_PRODUTO: `${BASE_URL}/produto/assinaProduto.php`,
    ASSINA_PRODUTO_DELETE: `${BASE_URL}/produto/assinaProdutoDelete.php`,
    READ_BY_NAME_USUARIO: `${BASE_URL}/usuario/usuarioReadByName.php`,
    READ_BY_USER_ID_PRODUTO_USUARIO: `${BASE_URL}/produto/produtoUsuarioReadByUserId.php`,
    READ_BY_USER_ID_RESULTADO: `${BASE_URL}/usuario/resultadoReadByUserId.php`,

    UPDATE_INSTITUCIONAL: `${BASE_URL}/institucional/institucionalUpdate.php`,
    READ_ALL_INSTITUCIONAL: `${BASE_URL}/institucional/institucionalReadAll.php`,
};
