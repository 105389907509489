import React from 'react';
import './ConTop.css';
import logo from '../../../images/logo_sante.png';

function ConTop() {
  return (
    <div className="ConTop">

      <div className='main_div_top'>
        <img className='img_logo_top' src={logo} alt="Logo" />
      </div>
      
    </div>
  );
}

export default ConTop;