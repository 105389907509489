import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Loader from '../loader/Loader';
import { URLS } from '../../../constants/urls';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';

function ConProdutos( { setHasLoadedTags, isLoading, setIsLoading, cpf, senha, idUser, tags, setTags, handleModalOpen, customStyles } ) {
    const [isTagFormModalOpen, setIsTagFormModalOpen] = useState(false);
    const [isEditTagModalOpen, setIsEditTagModalOpen] = useState(false);

    const [selectedTagForView, setSelectedTagForView] = useState(null);

    // Modal adição produto
    const nomeTag = useRef(null);
    const descricaoTag = useRef(null);
    const imagemTag = useRef(null);
    const [empresarialTag, setEmpresarialTag] = useState(null);

    // Modal edição produto
    const [selectedTag, setSelectedTag] = useState('');
    const editTagName = useRef(null);
    const editImagemTag = useRef(null);
    const editTagDescription = useRef();
    const [editTagEmpresarial, setEditTagEmpresarial] = useState(null);

    // Função de envio do novo produto
    const submitTagFunction = async (formData) => {
        
        try {
            const response = await axios.post(
                URLS.CREATE_PRODUTO,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data', // Atualiza para multipart/form-data
                    },
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição de todos os produtos (tags)
    const fetchTags = async () => {
        setHasLoadedTags(false);

        try {
            // Fazendo a requisição para obter todas as tags
            const response = await fetch(URLS.READ_ALL_PRODUTO, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ idUser, senha }), // Usando as chaves corretas
            });

            const data = await response.json();

            if (data.success) {
                // Mapeando os produtos retornados para o formato da tag com id e imagem
                const fetchedTags = data.products.map(prod => ({
                    nome: prod.name,
                    descricao: prod.description,
                    empresarial: prod.empresarial,
                    imagem: prod.imagem,
                    idUser: idUser,
                    senha: senha,
                    idTag: prod.id
                }));

                setTags(fetchedTags);
                setHasLoadedTags(true);
            } else {
                console.error(data.message);
            }
        } catch (error) {
            console.error('Erro ao buscar tags:', error);
        }
    };

    // Função para adicionar um produto (tag)
    const onSubmitTags = async (e) => {
        e.preventDefault();

        // Criação de um FormData para enviar dados multipart (necessário para enviar arquivos)
        const formData = new FormData();
        formData.append('nome', nomeTag.current.value);
        formData.append('descricao', descricaoTag.current.value);
        formData.append('empresarial', empresarialTag);
        formData.append('idUser', idUser); // Substitua por seu valor de idUser
        formData.append('senha', senha); // Substitua por sua variável de senha

        // Adiciona o arquivo de imagem, se houver
        if (imagemTag.current.files.length > 0) {
            formData.append('imagem', imagemTag.current.files[0]); // Seleciona o primeiro arquivo
        }

        setIsLoading(true);

        // Chama a função de submissão de tag
        const result = await submitTagFunction(formData); // Agora passando o FormData

        if (result.status) {
            M.toast({
                html: result.message,
                classes: 'toast_custom'
            });

            // Após adicionar a nova tag, busca todas as tags novamente para atualizar o estado com os IDs corretos
            await fetchTags();
        } else {
            M.toast({
                html: result.message,
                classes: 'toast_custom'
            });
        }

        setIsLoading(false);
        handleCloseTagFormModal();
    };

    // Função para editar um produto (tag)
    const onEditTag = async (e) => {
        e.preventDefault();

        // Obtendo a tag a ser editada pelo nome selecionado
        const tagToEdit = tags.find(tag => tag.nome === selectedTag);

        if (!tagToEdit) {
            M.toast({
                html: 'Produto não encontrado',
                classes: 'toast_custom'
            });

            return;
        }

        // Criando um objeto FormData para enviar os dados e a imagem
        const formData = new FormData();
        formData.append('id', tagToEdit.idTag);  // Utilizando o idTag correto
        formData.append('nome', editTagName.current.value);
        formData.append('descricao', editTagDescription.current.value);
        formData.append('empresarial', editTagEmpresarial);  // Manter o valor empresarial existente
        formData.append('idUser', idUser);
        formData.append('senha', senha);

        // Verifica se uma imagem foi selecionada e adiciona ao formData
        if (editImagemTag.current.files[0]) {
            formData.append('imagem', editImagemTag.current.files[0]);
        }

        setIsLoading(true);

        try {
            // Fazendo a requisição para atualizar o produto no backend
            const response = await fetch(URLS.UPDATE_PRODUTO, {
                method: 'POST',
                body: formData,  // Usando formData para enviar os dados do produto
            });

            const data = await response.json();

            if (data.success) {
                
                fetchTags();
                handleCloseEditTagModal();

                // Log para fins de depuração
                console.log(`O CPF "${cpf}" editou a tag "${selectedTag}" e mudou o nome para "${editTagName.current.value}"`);

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error('Erro ao atualizar produto:', error);
        }

        setIsLoading(false);
    };
    
    // Função para excluir um produto (tag)
    const onDeleteTag = async () => {
        if (!selectedTag) {
            M.toast({
                html: 'Selecione um produto!',
                classes: 'toast_custom'
            });

            return; // Verifica se há um produto selecionado
        }

        // Obtendo a tag a ser deletada pelo nome selecionado
        const tagToDelete = tags.find(tag => tag.nome === selectedTag);
    
        if (!tagToDelete) {
            M.toast({
                html: 'Produto não encontrado',
                classes: 'toast_custom'
            });

            return;
        }
    
        // Preparando os dados para a requisição de exclusão
        const deleteData = {
            id: tagToDelete.idTag, // Usando o idTag para identificar a tag no banco de dados
            idUser: idUser,
            senha: senha,
            auth: 'C$CKMkyaYNq5Dx?s' // Token de autenticação para a API
        };

        setIsLoading(true);
    
        try {
            // Fazendo a requisição para excluir o produto no backend
            const response = await fetch(URLS.DELETE_PRODUTO, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteData),
            });
    
            const data = await response.json();
    
            if (data.success) {
                // Atualizando o estado com a tag removida
                fetchTags();
                handleCloseEditTagModal();

                // Log para fins de depuração
                console.log(`O CPF "${cpf}" excluiu a tag "${selectedTag}"`);
                setSelectedTag('');

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error('Erro ao excluir produto:', error);
        }

        setIsLoading(false);
    };

    const handleCloseTagFormModal = () => {
        setIsTagFormModalOpen(false);
        setEmpresarialTag(null);
    };

    const handleCloseEditTagModal = () => {
        setIsEditTagModalOpen(false);
        setEditTagEmpresarial(null);
        setSelectedTag('');
    };

    useEffect(() => { // useEffect para que todos os vetores com os dados dos itens sejam carregados ao montar o componente
        fetchTags();
    }, []);

    return (
        <div className='ConProdutos'>

            {/* Condicional para mostrar o loader */}
            {isLoading && (
                <Loader></Loader>
            )}

            {/* Div Tags */}
            <div className='main_div_conts div_cinza'>
                <div className='row'>
                    <div className='col s6'>
                        <h3 className='text_title_conts'>Produtos:</h3>
                    </div>
                    <div className='col s6 row div_btn_cards right'>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsTagFormModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">add</i></button>
                        </div>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsEditTagModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">create</i></button> 
                        </div>
                    </div>
                </div>

                <div className="tag_section row">
                    {tags.length === 0 ? (
                        <div className="col">
                            <p className='no_text_conts flow-text'>Parece que ainda não temos produtos, adicione um para começar!</p>
                        </div>
                    ) : (
                        tags.map((tag, index) => (
                            <div
                                key={index}
                                className="tag_item col"
                                style={{ backgroundColor: '#041838', cursor: 'pointer' }}
                                onClick={() => setSelectedTagForView(tag)}
                            >
                                <p className='text_tag white-text'>{tag.nome}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* ----------------------------------- Seção dos Modais ----------------------------------- */}

            {/* Modal de adição de produto */}
            <Modal 
                isOpen={isTagFormModalOpen}
                onRequestClose={handleCloseTagFormModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Adição de Tags"
            >
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onSubmitTags}>
                        <div className="row">
                            <div className="col s12 div_input">
                                <label htmlFor="nomeTag">Nome do produto</label>
                                <input id="nomeTag" type="text" className="validate" required ref={nomeTag} autoComplete="off"/>
                            </div>

                            <div className="col s12 div_input">
                                <label htmlFor="descricaoTag">Descrição</label>
                                <textarea id="descricaoTag" className="materialize-textarea" required ref={descricaoTag} autoComplete="off"></textarea>
                            </div>

                            {/* Campo para adicionar imagem */}
                            <div className="col s12 div_input_img file-field input-field">

                                <div className='btn buttons_forms'>
                                    <span>Imagem do produto</span>
                                    <input id="imagemTag" type="file" ref={imagemTag} accept="image/*" required/>
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text"></input>
                                </div>

                            </div>

                            {/* Campo empresarial com input radio */}
                            <div className="col s12 div_input_radio">
                                <label>Empresarial?</label>
                                <p>
                                    <label>
                                        <input
                                            name="empresarial" 
                                            type="radio" 
                                            value="1" 
                                            checked={empresarialTag === '1'}
                                            onChange={(e) => setEmpresarialTag(e.target.value)} 
                                            required
                                        />
                                        <span>Sim</span>
                                    </label>
                                </p>
                                <p>
                                    <label>
                                        <input
                                            name="empresarial" 
                                            type="radio" 
                                            value="0" 
                                            checked={empresarialTag === '0'}
                                            onChange={(e) => setEmpresarialTag(e.target.value)} 
                                            required
                                        />
                                        <span>Não</span>
                                    </label>
                                </p>
                            </div>

                            <div className="col s6">
                                <button onClick={handleCloseTagFormModal} className="waves-effect waves-light waves-white btn left buttons_forms">
                                    Fechar
                                </button>
                            </div>
                            <div className="col s6">
                                <button className="waves-effect waves-light waves-white btn right buttons_forms" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal de edição de produto */}
            <Modal 
                isOpen={isEditTagModalOpen}
                onRequestClose={handleCloseEditTagModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edição de Tags"
            >
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onEditTag}>
                        <div className="row">
                            <div className="col s12 div_dropdown_input">
                                <select
                                    className="browser-default tag_dropdown_input"
                                    id="selectTag"
                                    value={selectedTag}
                                    onChange={(e) => setSelectedTag(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Selecione um produto</option>
                                    {tags.map((tag, index) => (
                                        <option key={index} value={tag.nome}>{tag.nome}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="col s12">
                                <label htmlFor="editTagName">Novo Nome da Tag</label>
                                <input id="editTagName" type="text" className="validate" required ref={editTagName} autoComplete="off" />
                            </div>
                            <div className="col s12">
                                <label htmlFor="editTagDescription">Descrição</label>
                                <textarea id="editTagDescription" className="materialize-textarea" required ref={editTagDescription} autoComplete="off"></textarea>
                            </div>

                            {/* Campo para adicionar imagem */}
                            <div className="col s12 div_input_img file-field input-field">
                                <div className='btn buttons_forms'>
                                    <span>Imagem do produto</span>
                                    <input id="editImagemTag" type="file" ref={editImagemTag} accept="image/*"/>
                                </div>
                                <div class="file-path-wrapper">
                                    <input class="file-path validate" type="text"></input>
                                </div>
                            </div>

                            {/* Campo empresarial com input radio */}
                            <div className="col s12 div_input_radio">
                                <label>Empresarial?</label>
                                <p>
                                    <label>
                                        <input
                                            name="editEmpresarial" 
                                            type="radio" 
                                            value="1" 
                                            checked={editTagEmpresarial === '1'}
                                            onChange={(e) => setEditTagEmpresarial(e.target.value)} 
                                            required
                                        />
                                        <span>Sim</span>
                                    </label>
                                </p>
                                <p>
                                    <label>
                                        <input
                                            name="editEmpresarial" 
                                            type="radio" 
                                            value="0" 
                                            checked={editTagEmpresarial === '0'}
                                            onChange={(e) => setEditTagEmpresarial(e.target.value)} 
                                            required
                                        />
                                        <span>Não</span>
                                    </label>
                                </p>
                            </div>

                            <div className="col s12">
                                <button type="button" onClick={onDeleteTag} className="waves-effect waves-light waves-white btn red buttons_forms">
                                    Excluir
                                </button>
                            </div>
                            <div className="col s6">
                                <button onClick={handleCloseEditTagModal} className="waves-effect waves-light waves-white btn left buttons_forms">
                                    Fechar
                                </button>
                            </div>
                            <div className="col s6">
                                <button className="waves-effect waves-light waves-white btn right buttons_forms" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal de visualização do produto */}
            <Modal
                isOpen={selectedTagForView !== null}
                onRequestClose={() => setSelectedTagForView(null)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Visualização de Produto"
            >
                {selectedTagForView && (
                    <div className='div_forms'>
                        <h4 className='text_title_show_modal'>Detalhes do Produto</h4>
                        <p className='text_show_modal'><strong>Nome:</strong> {selectedTagForView.nome}</p>
                        <p className='text_show_modal'><strong>Descrição:</strong> {selectedTagForView.descricao}</p>
                        
                        {/* Exibição do campo empresarial */}
                        <p className='text_show_modal'>
                            <strong>Empresarial:</strong> {selectedTagForView.empresarial === '1' ? 'Sim' : 'Não'}
                        </p>

                        {/* Exibição da imagem */}
                        {selectedTagForView.imagem && (
                            <div className="image_container">
                                <strong>Imagem:</strong>
                                <img src={selectedTagForView.imagem} alt="Imagem do Produto" style={{ maxWidth: '100%', height: 'auto', marginTop: '10px' }} />
                            </div>
                        )}

                        <button onClick={() => setSelectedTagForView(null)} className="waves-effect waves-light btn buttons_show_modal">Fechar</button>
                    </div>
                )}
            </Modal>

        </div>
    );
}

export default ConProdutos;