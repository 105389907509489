import axios from 'axios';
import React, { useState } from 'react';
import './App.css';
import ConLeft from './components/content/con_left/ConLeft';
import ConRight from './components/content/con_right/ConRight';
import ConTop from './components/content/con_top/ConTop';
import Home from './components/home/Home';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { URLS } from '../src/constants/urls';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cpfUser, setCpfUser] = useState("");
  const [senhaUser, setSenhaUser] = useState("");
  const [idUser, setIdUser] = useState("");

  const loginFunction = async (cpf, password) => {

    const formattedCPF = cpf;
    const formattedSenha = password.toString();

    const data = {
        telefone: formattedCPF,
        senha: formattedSenha,
    };

    try {
        const response = await axios.post(
        URLS.LOGIN_ADMIN,
        data,
        {
            headers: {
            'Content-Type': 'application/json'
            }
        }
        );

        if (response.data.success === true) {
          return {
              message: response.data.message,
              data: response.data.usuario,
              status: true,
          };
        } else {
          return {
              data: response.data.message,
              status: false,
          };
        }

    } catch (error) {
        return {
          data: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
          status: false,
        };
    }
  };

  const handleSubmit = async (cpf, senha) => {
    const result = await loginFunction(cpf, senha);

    if (result.status) {
      setIdUser(result.data.id);
      setCpfUser(result.data.cpf);
      setSenhaUser(senha);
      setIsLoggedIn(true); // Muda o estado para renderizar Home

      M.toast({
        html: result.message,
        classes: 'toast_custom'
      });
    } else {
      M.toast({
        html: result.data,
        classes: 'toast_custom'
      });
    }
  };

  const handleLogout = () => {
    setCpfUser("");
    setIsLoggedIn(false); // Redefine o estado para renderizar ConLeft e ConRight

    M.toast({
      html: 'Logout realizado com sucesso',
      classes: 'toast_custom'
    });
  };

  return (
    <div className="App">

      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isLoggedIn}
          timeout={500}
          classNames="fade"
        >

          {isLoggedIn ? ( // Renderizado quando está logado
          
            <div className='row'>
              <div className='col l12 s12'>
                <Home cpf={cpfUser} senha={senhaUser} idUser={idUser} handleLogout={handleLogout} />
              </div>
            </div>

          ) : ( // Renderizado quando não está logado

            <div className='row'>
              <div className='col l8 s12 div_top hide-on-large-only'>
                <ConTop />
              </div>
              <div className='col l8 s12 div_left hide-on-med-and-down'>
                <ConLeft />
              </div>
              <div className='col l4 s12 div_right'>
                <ConRight handleSubmit={handleSubmit} />
              </div>
            </div>

          )}

        </CSSTransition>
      </SwitchTransition>

    </div>
  );
}

export default App;