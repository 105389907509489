import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-modal';
import Loader from '../loader/Loader';
import { URLS } from '../../../constants/urls';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';

function ConTestes ({ setHasLoadedTests, isLoading, setIsLoading, cpf, senha, idUser, tags, tests, setTests, handleModalOpen, customStyles }) {
    const [isTestFormModalOpen, setIsTestFormModalOpen] = useState(false);
    const [isEditTestModalOpen, setIsEditTestModalOpen] = useState(false); 

    const [selectedTestForView, setSelectedTestForView] = useState(null);

    // Modal adição teste
    const [testName, setTestName] = useState('');
    const [testDescription, setTestDescription] = useState('');
    const [questions, setQuestions] = useState([]);
    const [selectedTags, setSelectedTags] = useState('');

    // Modal edição teste
    const [editTest, setEditTest] = useState(null);
    const [tagsFromTest, setTagsFromTest] = useState([]); // Armazena os produtos que o teste selecionado já possui
    const [tagsFromTestFix, setTagsFromTestFix] = useState([]);
    const [editTestName, setEditTestName] = useState('');
    const [editTestDescription, setEditTestDescription] = useState('');
    const [editTestQuestions, setEditTestQuestions] = useState([]);

    // Função para fazer a requisição para adicionar um teste
    const submitTestFunction = async (testData) => { //Adição de teste
        try {
            const response = await axios.post(
                URLS.CREATE_TESTE,
                testData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                    id: response.data.id,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para enviar os produtos associados ao teste
    const submitProdutoTesteFunction = async (produtoTesteData) => {
        try {
            const response = await axios.post(
                URLS.CREATE_PRODUTO_TESTE,
                produtoTesteData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição de exclusão de um ProdutoTeste
    const deleteProdutoTeste = async (produtoTesteData) => {
        try {
            const response = await axios.post(
                URLS.DELETE_PRODUTO_TESTE,
                produtoTesteData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            // Verifica se a resposta indica sucesso
            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para fazer a requisição de todos os testes
    const fetchTests = async () => {
        setHasLoadedTests(false);

        try {
            const response = await fetch(URLS.READ_ALL_TESTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idUser: idUser,
                    senha: senha,
                }),
            });

            // Tente converter a resposta para JSON
            const data = await response.json();

            // Verifique o sucesso da resposta
            if (data.success) {
                const parsedTests = data.tests.map((test) => ({
                    idTest: test.id,
                    nome: test.name,
                    descricao: test.description,
                    estrutura: JSON.parse(test.structure), // Convertendo a string de estrutura para JSON
                    idUser: idUser,
                    senha: senha,
                }));

                setTests(parsedTests);
                setHasLoadedTests(true);
            } else {
                console.error(data.message);
            }

        } catch (error) {
            console.error("Erro ao buscar testes:", error);
        }
    };

    // Função para fazer a requisição dos produtos relacionados a um teste
    const fetchProductsByTest = async (idTeste) => {
        try {
            const response = await fetch(URLS.READ_PRODUTO_TESTE_BY_TEST_ID, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    idTeste: idTeste,
                    idUser: idUser,
                    senha: senha,
                }),
            });

            // Verifica se a resposta tem sucesso (status 200-299)
            if (!response.ok) {
                console.error(`Erro na requisição: ${response.status} - ${response.statusText}`);
                return [];
            }

            // Verifica se a resposta contém algo antes de tentar convertê-la em JSON
            const textResponse = await response.text();
            if (!textResponse) {
                console.error("Resposta vazia do servidor.");
                return [];
            }

            // Tente converter a resposta para JSON
            const data = JSON.parse(textResponse);

            // Verifique o sucesso da resposta
            if (data.success) {
                // Mapeia os produtos retornando apenas os IDs dos produtos
                const productIds = data.produtosTeste.map((produto) => produto.idProduto);

                // Atualizando o estado tagsFromTest com os IDs dos produtos
                setTagsFromTest(productIds);
                setTagsFromTestFix(productIds);

                return productIds;
            } else {
                console.error(data.message);
                return [];
            }
        } catch (error) {
            console.error("Erro ao buscar produtos relacionados ao teste:", error);
            return [];
        }
    };

    const handleCloseTestFormModal = () => {
        setTestDescription('');
        setSelectedTags('');
        setQuestions([]);
        setIsTestFormModalOpen(false);
    };

    const closeEditTestModal = () => {
        setEditTest(null);
        setTagsFromTest('');
        setTagsFromTestFix('');
        setEditTestName('');
        setEditTestDescription('');
        setEditTestQuestions([]);
        setIsEditTestModalOpen(false);
    };

    // Funções de manipulação para adição, remoção e edição de perguntas e respostas para o modal de adição
    const handleAddQuestion = () => {
        setQuestions([...questions, { questionType: 'closed', questionText: '', answers: [''] }]);
    };

    const handleRemoveQuestion = (questionIndex) => {
        const newQuestions = questions.filter((_, i) => i !== questionIndex);
        setQuestions(newQuestions);
    };

    const handleRemoveAnswer = (questionIndex, answerIndex) => {
        const newQuestions = questions.map((q, i) => {
            if (i === questionIndex) {
                const newAnswers = q.answers.filter((_, j) => j !== answerIndex);
                return { ...q, answers: newAnswers };
            }
            return q;
        });

        setQuestions(newQuestions);
    };

    const handleQuestionChange = (questionIndex, value) => {
        const newQuestions = questions.map((q, i) => {
            if (i === questionIndex) {
                return { ...q, questionText: value };
            }
            return q;
        });

        setQuestions(newQuestions);
    };

    const handleQuestionTypeChange = (questionIndex, value) => {
        const newQuestions = questions.map((q, i) => {
            if (i === questionIndex) {
                return { ...q, questionType: value, answers: value === 'closed' ? [''] : [] };
            }
            return q;
        });

        setQuestions(newQuestions);
    };

    const handleAnswerChange = (questionIndex, answerIndex, value) => {
        const newQuestions = questions.map((q, i) => {
            if (i === questionIndex) {
                const newAnswers = q.answers.map((a, j) => {
                    if (j === answerIndex) {
                        return value;
                    }
                    return a;
                });
                return { ...q, answers: newAnswers };
            }
            return q;
        });

        setQuestions(newQuestions);
    };

    const handleAddAnswer = (questionIndex) => {
        const newQuestions = questions.map((q, i) => {
            if (i === questionIndex) {
                return { ...q, answers: [...q.answers, ''] };
            }
            return q;
        });

        setQuestions(newQuestions);
    };

    const handleTagSelection = (isChecked, idTag) => {
        if (isChecked) {
            setSelectedTags([...selectedTags, idTag]);
        } else {
            setSelectedTags(selectedTags.filter(tagId => tagId !== idTag));
        }

        console.log(selectedTags);
    };    

    // Função para adição de testes
    const onSubmitTests = async (e) => {
        e.preventDefault();

        const formattedQuestions = questions.map((question) => {
            if (question.questionType === 'closed') {
                return {
                    question: question.questionText,
                    options: question.answers.reduce((acc, answer, index) => {
                        const optionLabel = String.fromCharCode(65 + index);
                        acc[optionLabel] = answer;
                        return acc;
                    }, {}),
                    type: 'closed'
                };
            } else {
                return {
                    question: question.questionText,
                    type: 'open'
                };
            }
        });

        // Criando o objeto 'newTest' com a chave 'questions'
        const newTest = {
            nome: testName,
            descricao: testDescription,
            estrutura: JSON.stringify({ questions: formattedQuestions }),
            idUser: idUser,
            senha: senha,
        };

        setIsLoading(true);

        try {
            // Envia o teste
            const result = await submitTestFunction(newTest);

            if (result.status) {
                const testId = result.id;
                let res;

                // Envia cada tag (produto) associado ao teste criado
                for (const idProduto of selectedTags) {
                    
                    const produtoTesteData = {
                        idTeste: testId,
                        idProduto: idProduto,
                        idUser: idUser,
                        senha: senha,
                    };

                    res = await submitProdutoTesteFunction(produtoTesteData);
                }

                if(res.status){
                    // Atualiza a lista de testes
                    await fetchTests();

                    M.toast({
                        html: res.message,
                        classes: 'toast_custom'
                    });
                } else {
                    M.toast({
                        html: res.message,
                        classes: 'toast_custom'
                    });
                }
                
            } else {
                M.toast({
                    html: result.message,
                    classes: 'toast_custom'
                });
            }

        } catch (error) {
            console.error('Erro ao adicionar teste:', error);
            alert('Erro ao adicionar teste. Verifique a conexão.');
        }

        setIsLoading(false);
        handleCloseTestFormModal();
    };

    // Função para seleção de teste e atualização dos estados relevantes para o modal de edição
    const handleTestSelection = async (testId) => {
        const test = tests.find(t => t.idTest === testId);

        setTagsFromTest([]); // Limpar o estado de produtos anteriores
        setTagsFromTestFix([]);
        setEditTest(null);
        setEditTestName('');
        setEditTestDescription('');
        setEditTestQuestions([]);

        // Aguarda o retorno da função fetchProductsByTest
        const products = await fetchProductsByTest(testId);

        // Agora você pode garantir que 'products' está disponível
        products.forEach((productId) => {
            console.log(productId);  // Irá exibir o id de cada produto
        });

        if (test) {
            setEditTest(test);
            setEditTestName(test.nome);
            setEditTestDescription(test.descricao);

            // Sincronizar com as perguntas do teste selecionado, preservando o tipo de pergunta e as respostas
            const formattedQuestions = test.estrutura.questions.map(q => ({
                questionType: q.type,
                questionText: q.question,
                answers: q.type === 'closed' ? Object.values(q.options) : [],  // Extrair respostas de 'options' se for pergunta fechada
            }));

            setEditTestQuestions(formattedQuestions);
        } else {
            setEditTest(null);
            setEditTestName('');
            setEditTestDescription('');
            setEditTestQuestions([]);
            console.error("Teste não encontrado para o id:", testId);
        }
    };

    // Funções de manipulação para adição, remoção e edição de perguntas e respostas do modal de edição
    const handleEditTestAddQuestion = () => {
        setEditTestQuestions(prevQuestions => [...prevQuestions, { questionType: 'closed', questionText: '', answers: [''] }]);
    };

    const handleEditTestRemoveQuestion = (questionIndex) => {
        setEditTestQuestions(prevQuestions => prevQuestions.filter((_, i) => i !== questionIndex));
    };

    const handleEditTestRemoveAnswer = (questionIndex, answerIndex) => {
        setEditTestQuestions(prevQuestions => prevQuestions.map((q, i) => {
            if (i === questionIndex) {
                const newAnswers = q.answers.filter((_, j) => j !== answerIndex);
                return { ...q, answers: newAnswers };
            }
            return q;
        }));
    };

    const handleEditTestAddAnswer = (questionIndex) => {
        setEditTestQuestions(prevQuestions => prevQuestions.map((q, i) => {
            if (i === questionIndex) {
                return { ...q, answers: [...q.answers, ''] };
            }
            return q;
        }));
    };

    const handleEditTestQuestionChange = (questionIndex, value) => {
        setEditTestQuestions(prevQuestions => prevQuestions.map((q, i) => {
            if (i === questionIndex) {
                return { ...q, questionText: value };
            }
            return q;
        }));
    };

    const handleEditTestQuestionTypeChange = (questionIndex, value) => {
        
        setEditTestQuestions(prevQuestions => {
            const updatedQuestions = prevQuestions.map((q, i) => {
                if (i === questionIndex) {
                    const updatedQuestion = { ...q, questionType: value, answers: value === 'closed' ? [''] : [] };
                    return updatedQuestion;
                }
                return q;
            });
    
            return updatedQuestions;
        });

    };       

    const handleEditTestAnswerChange = (questionIndex, answerIndex, value) => {
        setEditTestQuestions(prevQuestions => prevQuestions.map((q, i) => {
            if (i === questionIndex) {
                const newAnswers = q.answers.map((a, j) => (j === answerIndex ? value : a));
                return { ...q, answers: newAnswers };
            }
            return q;
        }));
    };

    // Função para lidar com a seleção das tags
    const handleEditTagSelection = (isChecked, idTag) => {
        let updatedTags;

        if (isChecked) {
            // Se o checkbox foi marcado, adiciona o idTag ao array
            updatedTags = [...tagsFromTest, idTag];
        } else {
            // Se o checkbox foi desmarcado, remove o idTag do array
            updatedTags = tagsFromTest.filter(tagId => tagId !== idTag);
        }

        // Atualiza o estado com os ids selecionados
        setTagsFromTest(updatedTags);
        console.log(setTagsFromTest);
    }; 

    const onEditTest = async (e) => {
        e.preventDefault();

        // Formatando as perguntas
        const formattedQuestions = editTestQuestions.map((question) => {
            if (question.questionType === 'closed') {
                return {
                    question: question.questionText,
                    options: question.answers.reduce((acc, answer, index) => {
                        const optionLabel = String.fromCharCode(65 + index);
                        acc[optionLabel] = answer;
                        return acc;
                    }, {}),
                    type: 'closed'
                };
            } else {
                return {
                    question: question.questionText,
                    type: 'open'
                };
            }
        });
    
        // Criando o objeto 'updatedTest' com a chave 'questions'
        const updatedTest = {
            id: editTest.idTest,
            nome: editTestName,
            descricao: editTestDescription,
            estrutura: JSON.stringify({ questions: formattedQuestions }),  // Adiciona a chave 'questions' ao JSON
            idUser: idUser,
            senha: senha,
        };
    
        setIsLoading(true);

        try {
            const result = await fetch(URLS.UPDATE_TESTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedTest),
            });
    
            const data = await result.json();
    
            if (data.success) {
                // IDs que devem ser mantidos (estão em ambos)
                const toKeep = tagsFromTestFix.filter(id => tagsFromTest.includes(id));
                // IDs que devem ser criados (estão em tagsFromTest, mas não em tagsFromTestFix)
                const toCreate = tagsFromTest.filter(id => !tagsFromTestFix.includes(id));
                // IDs que devem ser excluídos (estão em tagsFromTestFix, mas não em tagsFromTest)
                const toDelete = tagsFromTestFix.filter(id => !tagsFromTest.includes(id));

                if(toCreate.length > 0){
                    // Envia cada tag (produto) associado ao teste criado
                    for (const idProduto of toCreate) {
                        
                        const produtoTesteData = {
                            idTeste: editTest.idTest,
                            idProduto: idProduto,
                            idUser: idUser,
                            senha: senha,
                        };

                        await submitProdutoTesteFunction(produtoTesteData); // Cria os registros
                    }
                }
                
                if(toDelete.length > 0){
                    // Envia cada tag (produto) associado ao teste criado
                    for (const idProduto of toDelete) {
                        
                        const produtoTesteData = {
                            idTeste: editTest.idTest,
                            idProduto: idProduto,
                            idUser: idUser,
                            senha: senha,
                        };

                        await deleteProdutoTeste(produtoTesteData); // Deleta os registros
                    }
                }

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });

                await fetchTests();  // Atualiza a lista de testes após a edição
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
        } catch (error) {
            console.error('Erro ao editar teste:', error);
        }
    
        setIsLoading(false);
        closeEditTestModal();
    };
      
    const onDeleteTest = async () => {
        if (!editTest) {
            M.toast({
                html: 'Selecione um teste!',
                classes: 'toast_custom'
            });

            return; // Verifica se há um teste selecionado
        }
    
        setIsLoading(true);

        try {
            const response = await fetch(URLS.DELETE_TESTE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: editTest.idTest,
                    idUser: idUser,
                    senha: senha,
                    auth: 'C$CKMkyaYNq5Dx?s' // Inclui o campo 'auth' conforme requerido pelo backend
                }),
            });

            const data = await response.json();
    
            if (data.success) {

                let res;
                // Envia cada tag (produto) associado ao teste criado
                for (const idProduto of tagsFromTestFix) {
                        
                    const produtoTesteData = {
                        idTeste: editTest.idTest,
                        idProduto: idProduto,
                        idUser: idUser,
                        senha: senha,
                    };

                    res = await deleteProdutoTeste(produtoTesteData); // Deleta os registros
                }

                if(res.status){
                    M.toast({
                        html: res.message,
                        classes: 'toast_custom'
                    });
                } else {
                    M.toast({
                        html: res.message,
                        classes: 'toast_custom'
                    });
                }

                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });

                fetchTests(); // Atualiza a lista de testes após a exclusão
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            }
    
        } catch (error) {
            console.error("Erro ao excluir o teste:", error);
        }

        setIsLoading(false);
        closeEditTestModal(); // Fecha o modal
    };

    useEffect(() => { // useEffect para que todos os vetores com os dados dos itens sejam carregados ao montar o componente
        fetchTests();
    }, []);

    return (
        <div className='ConTestes'>
            {/* Condicional para mostrar o loader */}
            {isLoading && (
                <Loader></Loader>
            )}

            <div className='main_div_conts div_cinza'> {/* Div Testes */}
                <div className='row'>
                    <div className='col s6'>
                        <h3 className='text_title_conts'>Testes:</h3>
                    </div>
                    <div className='col s6 row div_btn_cards right'>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsTestFormModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">add</i></button>
                        </div>
                        <div className=''>
                            <button onClick={() => handleModalOpen(setIsEditTestModalOpen)} className="btn-floating btn-large round_buttons waves-effect waves-light"><i class="material-icons">create</i></button> 
                        </div>
                    </div>
                </div>
                            
                <div className="test_section row">
                    {tests.length === 0 ? (
                        <div className="col">
                            <p className='no_text_conts flow-text'>Parece que ainda não temos testes, adicione um para começar!</p>
                        </div>
                    ) : (
                        tests.map((test, index) => (
                            <div
                                key={index}
                                className="tag_item col"
                                style={{ backgroundColor: '#041838', cursor: 'pointer' }}
                                onClick={() => setSelectedTestForView(test)}
                            >
                                <p className='text_tag white-text'>{test.nome}</p>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* ----------------------------------- Seção dos Modais ----------------------------------- */}

            {/* Modal de adição de teste */}
            <Modal 
                isOpen={isTestFormModalOpen}
                onRequestClose={handleCloseTestFormModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Adição de Testes"
            > 
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onSubmitTests}>
                        <div className="row">
                            {/* Checkbox para selecionar as tags */}
                            <div className="col s12 div_dropdown_input">
                                <label>Selecione as os produtos</label>
                                <div className="checkbox-group">
                                    {tags.map((tag, index) => (
                                        <label key={index}>
                                            <input
                                                className='filled-in checkbox_custom'
                                                type="checkbox"
                                                value={tag.idTag}
                                                checked={selectedTags.includes(tag.idTag)}
                                                onChange={(e) => handleTagSelection(e.target.checked, tag.idTag)}
                                            />
                                            <span className='text_checkbox'>{tag.nome}</span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div className="col s12">
                                <label htmlFor="nomeTest">Nome do Teste</label>
                                <input 
                                    id="nomeTest"
                                    type="text"
                                    className="validate"
                                    onChange={(e) => setTestName(e.target.value)}
                                    required
                                    autoComplete="off"
                                />
                            </div>

                            <div className="col s12">
                                <label htmlFor="testDescription">Descrição do Teste</label>
                                <textarea 
                                    id="testDescription" 
                                    className="materialize-textarea" 
                                    value={testDescription} 
                                    onChange={(e) => setTestDescription(e.target.value)}
                                    required
                                ></textarea>
                            </div>

                            {/* Seção das perguntas */}
                            {questions.map((question, qIndex) => (
                                <div key={qIndex} className="col s12 question_section">
                                    <div className="col s12">
                                        <label>Tipo da Pergunta</label>
                                        <select
                                            className="browser-default"
                                            value={question.questionType}
                                            onChange={(e) => handleQuestionTypeChange(qIndex, e.target.value)}
                                            required
                                        >
                                            <option value="closed">Fechada</option>
                                            <option value="open">Aberta</option>
                                        </select>
                                    </div>
                                    <div className="col s12">
                                        <label htmlFor={`questionText-${qIndex}`}>Texto da Pergunta</label>
                                        <input 
                                            id={`questionText-${qIndex}`} 
                                            type="text" 
                                            className="validate" 
                                            value={question.questionText} 
                                            onChange={(e) => handleQuestionChange(qIndex, e.target.value)} 
                                            required
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="col s12">
                                        <button type="button" className="waves-effect waves-light btn buttons_add_form_test" onClick={() => handleRemoveQuestion(qIndex)}>
                                            Remover Pergunta
                                        </button>
                                    </div>

                                    {question.questionType === 'closed' && question.answers.map((answer, aIndex) => (
                                        <div key={aIndex} className="col s12">
                                            <label htmlFor={`answerText-${qIndex}-${aIndex}`}>Resposta {aIndex + 1}</label>
                                            <input 
                                                id={`answerText-${qIndex}-${aIndex}`} 
                                                type="text" 
                                                className="validate" 
                                                value={answer} 
                                                onChange={(e) => handleAnswerChange(qIndex, aIndex, e.target.value)} 
                                                required
                                                autoComplete="off"
                                            />
                                            <button type="button" className="waves-effect waves-light btn buttons_add_form_test" onClick={() => handleRemoveAnswer(qIndex, aIndex)}>
                                                Remover Resposta
                                            </button>
                                        </div>
                                    ))}

                                    {question.questionType === 'closed' && (
                                        <div className="col s12">
                                            <button type="button" className="waves-effect waves-light buttons_add_form_test btn" onClick={() => handleAddAnswer(qIndex)}>
                                                Adicionar Resposta
                                            </button>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="col s12">
                                <button type="button" className="waves-effect waves-light btn buttons_add_form_test" onClick={handleAddQuestion}>
                                    Adicionar Pergunta
                                </button>
                            </div>

                            <div className="col s6">
                                <button onClick={handleCloseTestFormModal} className="waves-effect waves-light btn buttons_forms left">
                                    Fechar
                                </button>
                            </div>
                            <div className="col s6">
                                <button className="waves-effect waves-light btn buttons_forms right" type="submit">
                                    Confirmar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal de edição de teste */}
            <Modal 
                isOpen={isEditTestModalOpen}
                onRequestClose={closeEditTestModal}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Edição de Testes"
            > 
                <div className='div_forms'>
                    <form className="col s12" onSubmit={onEditTest}>
                        <div className="row">
                            <div className="col s12 div_dropdown_input">
                                <select
                                    className="browser-default tag_dropdown_input"
                                    value={editTest ? editTest.idTest : ""}
                                    onChange={(e) => handleTestSelection(e.target.value)}
                                    required
                                >
                                    <option value="" disabled>Selecione um teste</option>
                                    {tests.map((test, index) => (
                                        <option key={index} value={test.idTest}>{test.nome}</option>
                                    ))}
                                </select>
                            </div>

                            {editTest && (
                                <>
                                    {/* Checkbox para as tags */}
                                    <div className="col s12">
                                        <label>Selecione os novos produtos</label>
                                        <div className="checkbox-group">
                                            {tags.map((tag, index) => (
                                                <label key={index}>
                                                    <input
                                                        className='filled-in checkbox_custom'
                                                        type="checkbox"
                                                        value={tag.idTag}
                                                        checked={tagsFromTest.includes(tag.idTag)}
                                                        onChange={(e) => handleEditTagSelection(e.target.checked, tag.idTag)}
                                                    />
                                                    <span className='text_checkbox'>{tag.nome}</span>
                                                </label>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="col s12">
                                        <label htmlFor="editTestName">Nome do Teste</label>
                                        <input 
                                            id="editTestName"
                                            type="text"
                                            className="validate"
                                            value={editTestName}
                                            onChange={(e) => setEditTestName(e.target.value)}
                                            required
                                            autoComplete="off"
                                        />
                                    </div>

                                    <div className="col s12">
                                        <label htmlFor="editTestDescription">Descrição do Teste</label>
                                        <textarea 
                                            id="editTestDescription" 
                                            className="materialize-textarea" 
                                            value={editTestDescription} 
                                            onChange={(e) => setEditTestDescription(e.target.value)}
                                            required
                                        ></textarea>
                                    </div>

                                    {editTestQuestions.map((question, qIndex) => (
                                        <div key={qIndex} className="col s12 question_section">
                                            <div className="col s12">
                                                <label>Tipo da Pergunta</label>
                                                <select
                                                    className="browser-default"
                                                    value={question.questionType}
                                                    onChange={(e) => handleEditTestQuestionTypeChange(qIndex, e.target.value)}
                                                    required
                                                >
                                                    <option value="closed">Fechada</option>
                                                    <option value="open">Aberta</option>
                                                </select>
                                            </div>
                                            <div className="col s12">
                                                <label htmlFor={`editQuestionText-${qIndex}`}>Texto da Pergunta</label>
                                                <input 
                                                    id={`editQuestionText-${qIndex}`} 
                                                    type="text" 
                                                    className="validate" 
                                                    value={question.questionText} 
                                                    onChange={(e) => handleEditTestQuestionChange(qIndex, e.target.value)} 
                                                    required
                                                    autoComplete="off"
                                                />
                                            </div>

                                            <div className="col s12">
                                                <button type="button" className="waves-effect waves-light btn red buttons_add_form_test" onClick={() => handleEditTestRemoveQuestion(qIndex)}>
                                                    Remover Pergunta
                                                </button>
                                            </div>

                                            {question.questionType === 'closed' && question.answers && question.answers.length > 0 && question.answers.map((answer, aIndex) => (
                                                <div key={aIndex} className="col s12">
                                                    <label htmlFor={`editAnswerText-${qIndex}-${aIndex}`}>Resposta {aIndex + 1}</label>
                                                    <input 
                                                        id={`editAnswerText-${qIndex}-${aIndex}`} 
                                                        type="text" 
                                                        className="validate" 
                                                        value={answer} 
                                                        onChange={(e) => handleEditTestAnswerChange(qIndex, aIndex, e.target.value)} 
                                                        required
                                                        autoComplete="off"
                                                    />
                                                    <button type="button" className="waves-effect waves-light btn red buttons_add_form_test" onClick={() => handleEditTestRemoveAnswer(qIndex, aIndex)}>
                                                        Remover Resposta
                                                    </button>
                                                </div>
                                            ))}

                                            {question.questionType === 'closed' && (
                                                <div className="col s12">
                                                    <button type="button" className="waves-effect waves-light buttons_add_form_test btn" onClick={() => handleEditTestAddAnswer(qIndex)}>
                                                        Adicionar Resposta
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    ))}

                                    <div className="col s12">
                                        <button type="button" className="waves-effect waves-light btn buttons_add_form_test" onClick={handleEditTestAddQuestion}>
                                            Adicionar Pergunta
                                        </button>
                                    </div>

                                    <div className="col s12">
                                        <button type="button" className="waves-effect waves-light btn red buttons_forms left" onClick={onDeleteTest}>
                                            Excluir
                                        </button>
                                    </div>
                                    <div className="col s6">
                                        <button type="button" onClick={closeEditTestModal} className="waves-effect waves-light btn buttons_forms left">
                                            Fechar
                                        </button>
                                    </div>
                                    <div className="col s6">
                                        <button className="waves-effect waves-light btn buttons_forms right" type="submit">
                                            Confirmar
                                        </button>
                                    </div>
                                </>
                            )}

                            { !editTest &&
                                <>
                                    <div className='col s12'>
                                        <button type="button" onClick={closeEditTestModal} className="waves-effect waves-light btn buttons_forms">
                                            Fechar
                                        </button>  
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </Modal>

            {/* Modal de visualização de teste */}
            <Modal
                isOpen={selectedTestForView !== null}
                onRequestClose={() => setSelectedTestForView(null)}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Visualização de Teste"
            >
                {selectedTestForView && (
                    <div className='div_forms'>
                        <h4 className='text_title_show_modal'>Detalhes do Teste</h4>
                        
                        <p className='text_show_modal'><strong>Nome:</strong> {selectedTestForView.nome}</p>
                        <p className='text_show_modal'><strong>Descrição:</strong> {selectedTestForView.descricao}</p>
                        
                        <h5 className='text_subtitle_show_modal'>Conteúdo:</h5>

                        {selectedTestForView.estrutura && selectedTestForView.estrutura.questions ? (
                            selectedTestForView.estrutura.questions.map((question, index) => (
                                <div key={index} className="question_details">
                                    <p className='text_show_modal'><strong>Pergunta:</strong> {question.question}</p>
                                    <p className='text_show_modal'><strong>Tipo:</strong> {question.type === 'closed' ? 'Fechada' : question.type === 'open' ? 'Aberta' : question.type}</p>
                                    {question.type === 'closed' && question.options && (
                                        <div>
                                            <p className='text_show_modal'><strong>Opções:</strong></p>
                                            <ul className='text_show_modal'>
                                                {Object.entries(question.options).map(([key, value]) => (
                                                    <li key={key}>{key}: {value}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <p>Nenhuma pergunta disponível.</p>
                        )}

                        <button onClick={() => setSelectedTestForView(null)} className="waves-effect waves-light waves-white btn buttons_show_modal">Fechar</button>
                    </div>
                )}
            </Modal>

        </div>
    );
}

export default ConTestes;