import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Loader from '../loader/Loader';
import { URLS } from '../../../constants/urls';
import 'materialize-css/dist/css/materialize.min.css';
import M from 'materialize-css';

function ConUsuarios({ isLoading, setIsLoading, cpf, senha, idUser, tags, tests, handleModalOpen, customStyles }) {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    
    const [selectedUser, setSelectedUser] = useState(null); // Estado para o usuário selecionado

    const [isUserModalOpen, setIsUserModalOpen] = useState(false); // Estado para controlar o modal
    const [isEditSignModalOpen, setIsEditSignModalOpen] = useState(false);

    const [userList, setUserList] = useState([]);
    const [noUserFind, setNoUserFind] = useState(false);
    const [showUserList, setShowUserList] = useState(false);
    const [productList, setProductList] = useState([]); // Estado para a lista de produtos do usuário
    const [resultList, setResultList] = useState([]); // Estado para a lista de resultados do usuário

    const [selectedAddProduct, setSelectedAddProduct] = useState(''); // Estado para o produto a adicionar
    const [selectedRemoveProduct, setSelectedRemoveProduct] = useState(''); // Estado para o produto a remover

    // Função para fazer a requisição para adicionar um registro de produto para um usuário
    const submitProductSubscription = async (productData) => {
        try {
            const response = await axios.post(
                URLS.ASSINA_PRODUTO,
                productData,
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.data.success === true) {
                return {
                    message: response.data.message,
                    status: true,
                };
            } else {
                return {
                    message: response.data.message,
                    status: false,
                };
            }

        } catch (error) {
            return {
                message: 'Erro ao conectar com o servidor, verifique sua conexão com a internet.',
                status: false,
            };
        }
    };

    // Função para buscar usuários pelo nome no backend
    const fetchUsersByName = async (name) => {
        try {
            const response = await axios.post(URLS.READ_BY_NAME_USUARIO, {
                nome: name
            });

            if (response.data.success) {
                const filteredUsers = response.data.usuarios;
                
                // Filtrar nomes duplicados para a barra de pesquisa
                const uniqueNames = Array.from(new Set(filteredUsers.map(user => user.nome)));
                setSuggestions(uniqueNames);

                // Armazenar todos os usuários com o nome correspondente
                setUserList(filteredUsers);
            } else {
                setSuggestions([]);
                setUserList([]);
            }
        } catch (error) {
            console.error('Erro ao buscar usuários:', error);
        }
    };

    // Função para buscar produtos pelo ID do usuário no backend
    const fetchUserProductsByUserId = async (idUser) => {
        try {
            const response = await axios.post(URLS.READ_BY_USER_ID_PRODUTO_USUARIO, {
                idUser: idUser
            });

            if (response.data.success) {
                const userProducts = response.data.produtos;

                // Armazenar a lista de produtos do usuário
                setProductList(userProducts);
            } else {
                setProductList([]); // Limpar a lista se nenhum produto for encontrado
            }
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        }
    };

    // Função para buscar resultados pelo ID do usuário no backend
    const fetchUserResultsByUserId = async (idUser) => {
        try {
            const response = await axios.post(URLS.READ_BY_USER_ID_RESULTADO, {
                idUser: idUser
            });

            if (response.data.success) {
                const userResults = response.data.resultados;

                // Armazenar a lista de resultados do usuário
                setResultList(userResults);
            } else {
                setResultList([]); // Limpar a lista se nenhum resultado for encontrado
            }
        } catch (error) {
            console.error('Erro ao buscar resultados:', error);
        }
    };

    // Função para lidar com a mudança de entrada na barra de pesquisa
    const handleInputChange = (e) => {
        const value = e.target.value;

        setQuery(value);
        setShowUserList(false);

        if (value.length >= 4) {
            fetchUsersByName(value); // Faz a busca no backend
        } else {
            setSuggestions([]); // Limpa as sugestões se houver menos de 4 caracteres
        }
    };

    // Função para lidar com a seleção de um nome de usuário
    const handleSelectUser = (nome) => {
        // Filtra todos os usuários com o mesmo nome e os exibe
        const usersWithSameName = userList.filter(user => user.nome === nome);
        setUserList(usersWithSameName);
        setShowUserList(true);
        setSuggestions([]); // Limpa as sugestões após a seleção
        setQuery(''); // Reseta a barra de pesquisa
    };

    // Função para realizar a pesquisa ao pressionar "Enter" ou clicar no botão de pesquisa
    const handleSearch = () => {
        if (query.length > 0) {
            setIsLoading(true);

            fetchUsersByName(query); // Faz a busca no backend com o nome completo ou parcial
            setQuery(''); // Reseta a barra de pesquisa

            setIsLoading(false);
            setShowUserList(true);

            if(userList.length > 0) {
                setNoUserFind(false);
                setSuggestions([]); // Limpa as sugestões após a pesquisa
            } else {
                setNoUserFind(true);
            }
        } else {
            M.toast({
                html: 'Digite algum nome!',
                classes: 'toast_custom'
            });
        }
    };

    // Função para abrir o modal com os dados do usuário selecionado
    const handleOpenUserModal = async (user) => {
        setSelectedUser(user);
        setIsUserModalOpen(true);

        setIsLoading(true);

        await fetchUserProductsByUserId(user.id); // Buscar os produtos do usuário
        await fetchUserResultsByUserId(user.id); // Buscar os resultados do usuário

        setIsLoading(false);
    };

    // Função para fechar o modal
    const handleCloseUserModal = () => {
        setIsUserModalOpen(false);
        setSelectedUser(null);
        setProductList([]); // Limpar a lista de produtos ao fechar o modal
        setResultList([]); // Limpar a lista de resultados ao fechar o modal
    };

    // Função para abrir o modal com os dados do usuário selecionado
    const handleOpenEditSignModal = async (user) => {
        setSelectedUser(user);
        setIsEditSignModalOpen(true);

        setIsLoading(true);

        await fetchUserProductsByUserId(user.id); // Buscar os produtos do usuário
        await fetchUserResultsByUserId(user.id); // Buscar os resultados do usuário

        setIsLoading(false);
    };

    // Função para fechar o modal de edição e limpar os campos de formulário
    const handleCloseEditSignModal = () => {
        setIsEditSignModalOpen(false);
        setSelectedUser(null);
        setProductList([]);
        setResultList([]);
        setSelectedAddProduct('');  // Limpar o campo de adicionar produto
        setSelectedRemoveProduct('');  // Limpar o campo de remover produto
    };

    // Função para adicionar um produto à assinatura do usuário
    const onSubmitAddProduct = async (event) => {
        event.preventDefault(); // Previne o comportamento padrão do form

        // Verifica se o usuário e o produto foram selecionados
        if (!selectedUser || !selectedAddProduct) {
            alert('Por favor, selecione um produto e tente novamente.');
            return;
        }

        // Criação dos dados necessários para enviar à API
        const productData = {
            idProduto: selectedAddProduct,  // ID do produto selecionado no modal
            idUser: selectedUser.id,    // ID do usuário selecionado
            senha: senha             // Senha do usuário logado
        };

        setIsLoading(true);

        // Chama a função que faz a requisição para adicionar a assinatura de produto
        const result = await submitProductSubscription(productData);

        // Verifica o status da operação e exibe a mensagem correspondente
        if (result.status) {
            M.toast({
                html: result.message,
                classes: 'toast_custom'
            });
        } else {
            M.toast({
                html: result.message,
                classes: 'toast_custom'
            });
        }

        setIsLoading(false);
        handleCloseEditSignModal(); // Fecha o modal após sucesso
    };

    const onSubmitRemoveProduct = async (event) => {
        event.preventDefault(); // Impede o envio padrão do formulário
    
        if (!selectedRemoveProduct) {
            alert("Selecione um produto para remover!");
            return; // Verifica se há um produto selecionado
        }
    
        setIsLoading(true);

        try {
            const response = await fetch(URLS.ASSINA_PRODUTO_DELETE, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: selectedRemoveProduct,  // ID do produto a ser removido
                    idUser: selectedUser.id,     // ID do usuário
                    senha: senha,                // Senha do usuário
                    auth: 'C$CKMkyaYNq5Dx?s'     // Campo 'auth' conforme requerido pelo backend
                }),
            });

            const data = await response.json();
    
            if (data.success) {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                });
            } else {
                M.toast({
                    html: data.message,
                    classes: 'toast_custom'
                }); // Exibe a mensagem de erro vinda do servidor
            }
    
        } catch (error) {
            console.error("Erro ao excluir o produto:", error);
        }

        // Fecha o modal de edição de assinaturas
        handleCloseEditSignModal();
        setIsLoading(false);
    };    

    // Função para lidar com a tecla "Enter" na barra de pesquisa
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch(); // Executa a pesquisa ao pressionar "Enter"
        }
    };

    return (
        <div className="ConUsuarios">
            {/* Condicional para mostrar o loader */}
            {isLoading && (
                <Loader></Loader>
            )}

            {/* Barra de pesquisa com autocomplete */}
            <div className='input_searchbar row'>
                <div className='col l10 s12'>
                    <h4 className='text_title_conts'>Digite o nome do usuário:</h4>
                </div>

                <div className='col s10'>
                    <input
                        type="text"
                        value={query}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress} // Detecta "Enter"
                    />
                    
                </div>

                <div className='col l2 s12'>
                    <button onClick={handleSearch} className="waves-effect waves-light waves-white btn buttons_user_list">Pesquisar</button> {/* Botão de pesquisa */}
                </div>
            </div>
            
            {/* Sugestões de autocomplete */}
            {(suggestions.length > 0 && query.length >= 4) && (
                <ul style={{ border: '1px solid #ccc', maxHeight: '150px', overflowY: 'auto', padding: '10px' }}>
                    {suggestions.map((nome, index) => (
                        <li 
                            key={index} 
                            onClick={() => handleSelectUser(nome)}
                            style={{ cursor: 'pointer', padding: '5px', borderBottom: '1px solid #eee' }}
                        >
                            {nome}
                        </li>
                    ))}
                </ul>
            )}

            { showUserList ? (
                noUserFind ? (
                    <h3>Nenhum usuário encontrado.</h3>
                ) : (
                    userList.length > 0 && (
                        <div className="user-list">
                            <h4 className='text_title_conts'>Usuários Encontrados:</h4>
                            <ul className='user_list'>
                                {userList.map((user, index) => (
                                    <div className='row div_user_list_item' key={index}>
                                        <div className='col l10 s12'>
                                            <li 
                                                className='user_list_item'
                                                onClick={() => handleOpenUserModal(user)} // Abre o modal ao clicar
                                            >
                                                <p>{user.nome} - {user.telefone}</p>
                                            </li>
                                        </div>
                                        <div className='col l2 s12'>
                                            <button
                                                onClick={() => handleOpenEditSignModal(user)}
                                                className="waves-effect waves-light waves-white btn left buttons_user_list"
                                            >
                                                Editar assinaturas
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    )
                )
            ) : null }

            {/* ----------------------------------- Seção dos Modais ----------------------------------- */}

            {/* Modal com os dados do usuário */}
            <Modal
                isOpen={isUserModalOpen}
                onRequestClose={handleCloseUserModal}
                contentLabel="Dados do Usuário"
                style={customStyles}
                ariaHideApp={false}
            >
                {selectedUser ? (
                    <div className="row div_forms">
                        <h4 className="text_title_show_modal">{selectedUser.nome}</h4>

                        <div className='col s12'>
                            <p className="text_show_modal">
                                <strong>Email:</strong> {selectedUser.email}
                            </p>
                            <p className="text_show_modal">
                                <strong>Telefone:</strong> {selectedUser.telefone}
                            </p>
                            <p className="text_show_modal">
                                <strong>CPF:</strong> {selectedUser.cpf}
                            </p>
                            <p className="text_show_modal">
                                <strong>Último IP:</strong> {selectedUser.last_ip}
                            </p>
                        </div>
                        
                        <div className='col s12'>
                            {/* Exibição da lista de produtos do usuário */}
                            {productList.length > 0 ? (
                                <div>
                                    <h5 className="text_title_show_modal">Produtos do Usuário:</h5>

                                    <ul>
                                        {productList.map((product, index) => {
                                            const productName = tags.find((tag) => tag.idTag === product.idProduto)?.nome || 'Nome do Produto Desconhecido';

                                            return (
                                                <li key={index}>
                                                    <p className="text_show_modal">
                                                        <strong>Produto:</strong> {productName} - <strong>Momento:</strong> {product.momento}
                                                    </p>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ) : (
                                <h5 className="text_title_show_modal">Nenhum produto encontrado para este usuário.</h5>
                            )}
                        </div>
                        
                        <div className='col s12'>
                            {/* Exibição da lista de resultados do usuário */}
                            {resultList.length > 0 ? (
                                <div>
                                    <h5 className="text_title_show_modal">Resultados do Usuário:</h5>

                                    <ul>
                                        {resultList.map((result, index) => {
                                            const testName = tests.find((test) => test.idTest === result.idTeste)?.nome || 'Nome do Teste Desconhecido';

                                            return (
                                                <li key={index}>
                                                    <p className="text_show_modal">
                                                        <strong>Teste:</strong> {testName} - <strong>Momento:</strong> {result.momento}
                                                    </p>

                                                    {/* Exibição formatada do campo estrutura */}
                                                    <div>
                                                        <p className="text_show_modal">
                                                            <strong>Estrutura:</strong>
                                                        </p>
                                                        {(() => {
                                                            try {
                                                                const estruturaObj = JSON.parse(result.estrutura);

                                                                return (
                                                                    <ul>
                                                                        {Object.entries(estruturaObj).map(([key, value], idx) => (
                                                                            <li key={idx}>
                                                                                <p className="text_show_modal_sub">
                                                                                    <strong>{key}:</strong>{' '}
                                                                                    {typeof value === 'boolean' ? (value ? 'Sim' : 'Não') : value}
                                                                                </p>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                );
                                                            } catch (e) {
                                                                // Se não for possível fazer o parse, exibe a estrutura como está
                                                                return <p>{result.estrutura}</p>;
                                                            }
                                                        })()}
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ) : (
                                <h5 className="text_title_show_modal">Nenhum resultado encontrado para este usuário.</h5>
                            )}
                        </div>

                        <div className="col s12">
                            <button
                                onClick={handleCloseUserModal}
                                className="waves-effect waves-light waves-white btn left buttons_forms"
                            >
                                Fechar
                            </button>
                        </div>
                        
                    </div>
                ) : (
                    <p>Carregando dados...</p>
                )}
            </Modal>

            {/* Modal para editar assinatura */}
            <Modal
                isOpen={isEditSignModalOpen}
                onRequestClose={handleCloseEditSignModal}
                contentLabel="Editar Assinatura"
                style={customStyles}
                ariaHideApp={false}
            >
                {selectedUser ? (
                    <div className="row div_forms">
                        <h4 className="text_title_show_modal">Editar Assinatura de {selectedUser.nome}</h4>

                        {/* Formulário Adicionar assinatura */}
                        <div className="col s12">
                            <h5>Adicionar Assinatura</h5>

                            <form onSubmit={onSubmitAddProduct}>
                                <div className='row'>
                                    <div className='col s12'>
                                        <label className='text_add_sign' htmlFor="add-signature">Selecione o produto para adicionar:</label>

                                        <select 
                                            id="add-signature" 
                                            className="browser-default" 
                                            value={selectedAddProduct} 
                                            onChange={(e) => setSelectedAddProduct(e.target.value)} 
                                            required
                                        >
                                            <option value="" disabled>Selecione um produto</option>
                                            {tags
                                                .filter(tag => !productList.some(product => product.idProduto === tag.idTag))
                                                .map((tag) => (
                                                    <option key={tag.idTag} value={tag.idTag}>
                                                        {tag.nome}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                    </div>

                                    <div className='col s12'>
                                        <button type="submit" className="waves-effect waves-light btn buttons_add_form_test">Adicionar Produto</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        {/* Formulário Excluir assinatura */}
                        <div className="col s12">
                            <h5>Excluir Assinatura</h5>

                            <form onSubmit={onSubmitRemoveProduct}>
                                <div className='row'>
                                    <div className='col s12'>
                                        <label className='text_add_sign' htmlFor="remove-signature">Selecione o produto para remover:</label>

                                        <select 
                                            id="remove-signature" 
                                            className="browser-default" 
                                            value={selectedRemoveProduct} 
                                            onChange={(e) => setSelectedRemoveProduct(e.target.value)} 
                                            required
                                        >
                                            <option value="" disabled>Selecione um produto</option>
                                            {productList.map((product) => (
                                                <option key={product.idProduto} value={product.idProduto}>
                                                    {tags.find(tag => tag.idTag === product.idProduto)?.nome || 'Produto Desconhecido'}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    
                                    <div className='col s12'>
                                        <button type="submit" className="waves-effect waves-light btn buttons_add_form_test">Remover Produto</button>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div className="col s12">
                            <button
                                onClick={handleCloseEditSignModal}
                                className="waves-effect waves-light btn buttons_forms"
                            >
                                Fechar
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>Carregando dados...</p>
                )}
            </Modal>

        </div>
    );
}

export default ConUsuarios;